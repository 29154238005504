// src/setupStreams.js

import {
  Readable,
  Writable,
  Duplex,
  Transform,
  PassThrough,
} from "stream-browserify";

if (typeof window !== "undefined") {
  window.stream = {
    Readable,
    Writable,
    Duplex,
    Transform,
    PassThrough,
  };
}
