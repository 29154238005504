// src/App.js

import React from "react";
import "./App.css";
import Sender from "./Sender";

const App = () => {
  const senderId = "sender1";
  const receiverId = "receiver1";

  return (
    <div className="App">
      <header className="App-header">
        <Sender senderId={senderId} receiverId={receiverId} />
      </header>
    </div>
  );
};

export default App;
