import React, { useEffect, useState, useRef } from "react";
import SimplePeer from "simple-peer";
import io from "socket.io-client";

const SIGNALING_SERVER_URL = "https://joycon.ottonomy.io";

const Sender = ({ receiverId, senderId }) => {
  //const [socket, setSocket] = useState(null);
  const peerRef = useRef(null);
  const connectSuccessfulRef = useRef(false);
  const connectIntervalRef = useRef(null);
  const sendIntervalRef = useRef(null);

  const createPeer = (socketConnection) => {
    clearInterval(connectIntervalRef.current);

    const peerOptions = {
      initiator: true,
      trickle: false,
      config: {
        iceServers: [
          { urls: "stun:stun.l.google.com:19302" },
          { urls: "stun:stun1.l.google.com:19302" },
          { urls: "stun:stun2.l.google.com:19302" },
          { urls: "stun:stun3.l.google.com:19302" },
          { urls: "stun:stun4.l.google.com:19302" },
        ],
      },
    };

    const newPeer = new SimplePeer(peerOptions);

    newPeer.on("data", (data) => {
      console.log("test");
      //if (newPeer && newPeer.readable && newPeer.readable.readableState) {
      const dataString = data.toString();
      console.log(dataString);
      if (dataString.startsWith("pong_")) {
        const [, uniqueId, sentTimestamp] = dataString.split("_");
        const rttValue = Date.now() - parseInt(sentTimestamp, 10);
        //setRtt(rttValue); // Update the RTT state variable
        console.log(
          `Received pong message with ID ${uniqueId}. Round trip time: ${rttValue} ms`
        );
      } else if (dataString.startsWith("data_")) {
        const [, randomNumber] = dataString.split("_");
        console.log(`Received random number ${randomNumber} from receiver`);
      } else {
        console.log(`Received message from receiver: ${dataString}`);
      }
      //}
      // else {
      //   console.log("Peer connection is no longer active.");
      // }
    });
    if (socketConnection && socketConnection.emit) {
      newPeer.on("signal", (data) => {
        socketConnection.emit(
          "message",
          JSON.stringify({
            receiverId,
            senderId,
            signalData: data,
          })
        );
      });
    }

    if (newPeer && newPeer.on) {
      newPeer.on("connect", () => {
        console.log(`Sender connected to receiver with ID ${receiverId}`);
        connectSuccessfulRef.current = true;
        //this.pingInterval = setInterval(() => this.sendPing(), 5000);
        sendIntervalRef.current = setInterval(sendData, 1000);
      });

      newPeer.on("close", () => {
        console.log(`Sender disconnected from receiver with ID ${receiverId}`);
        connectSuccessfulRef.current = false;
        clearInterval(sendIntervalRef.current); // Clear the interval here
        clearInterval(connectIntervalRef.current);
        //clearInterval(this.pingInterval); // Clear the ping interval
        connectIntervalRef.current = setInterval(() => {
          if (!connectSuccessfulRef.current) {
            console.log(
              `Trying to reconnect to receiver with ID ${receiverId}`
            );
            createPeer(socketConnection);
          }
        }, 5000);
      });

      newPeer.on("error", (err) => {
        console.log(`Sender error: ${err}`);
        clearInterval(connectIntervalRef.current);
        //clearInterval(this.pingInterval); // Clear the ping interval
        try {
          connectIntervalRef.current = setInterval(() => {
            if (!connectSuccessfulRef.current) {
              console.log(
                `Trying to reconnect to receiver with ID ${receiverId}`
              );
              createPeer(socketConnection);
            }
          }, 5000);
        } catch (error) {
          console.log("Error while trying to reconnect:", error);
        }
      });

      peerRef.current = newPeer;
    } else {
      console.log("newPeer is null or does not have an on method");
    }
  };

  const sendData = () => {
    if (
      peerRef.current &&
      connectSuccessfulRef.current &&
      peerRef.current._channel &&
      peerRef.current._channel.readyState === "open"
    ) {
      const randomNumber = Math.floor(Math.random() * 100);
      const dataMessage = `data_${randomNumber}`;
      console.log(`Sending random number ${randomNumber} to receiver`);
      try {
        peerRef.current.send(dataMessage);
      } catch (err) {
        console.error("Failed to send data:", err);
        if (err.message.includes("_readableState")) {
          console.log("Gracefully handling _readableState error.");
        }
      }
    } else {
      console.log("Peer connection not active, skipping data send");
    }
  };

  const sendPing = () => {
    const uniqueId = Math.random().toString(36).substr(2, 9);
    const pingMessage = `ping_${uniqueId}_${Date.now()}`;
    try {
      peerRef.current.send(pingMessage);
      console.log(`Sent ping message: ${pingMessage}`);
    } catch (err) {
      console.error("Failed to send ping message:", err);
    }
  };

  useEffect(() => {
    const socketConnection = io(SIGNALING_SERVER_URL);
    //setSocket(socketConnection);

    // Check if there's a saved connection status in local storage
    const wasConnected = localStorage.getItem("connected") === "true";
    if (wasConnected) {
      console.log("Trying to reconnect after browser refresh");
      createPeer(socketConnection);
    }

    socketConnection.on("message", (data) => {
      const parsedData = JSON.parse(data);
      if (
        parsedData.receiverId === receiverId &&
        parsedData.senderId === senderId
      ) {
        if (peerRef.current && !peerRef.current.destroyed) {
          peerRef.current.signal(parsedData.signalData);
        }
      }
    });

    createPeer(socketConnection);

    if (connectSuccessfulRef.current) {
      sendIntervalRef.current = setInterval(sendData, 10000);
    } else {
      clearInterval(sendIntervalRef.current);
    }
    setInterval(sendPing, 5000); // Send a ping message every 5 seconds
    return () => {
      if (peerRef.current) {
        peerRef.current.destroy(); // Destroy the Readable stream
      }
      socketConnection.disconnect();
      clearInterval(connectIntervalRef.current);
      clearInterval(sendIntervalRef.current);

      if (connectSuccessfulRef.current) {
        localStorage.setItem("connected", true);
      } else {
        localStorage.removeItem("connected");
      }
    };
  }, [receiverId, senderId]); //, connectSuccessfulRef.current]);

  return (
    <div>
      <h1>Sender</h1>
      <p>Sender ID: {senderId}</p>
      <p>Receiver ID: {receiverId}</p>
    </div>
  );
};
export default Sender;
